<template>
  <div>
    <table class="table" v-if="done">
      <tbody>
        <tr v-for="ddc in Object.keys(books)" v-bind:key="ddc">
          <td><strong>{{ ddc }}</strong>
            <div v-show="deweys[ddc]" class="is-size-7"><em>{{ deweys[ddc] }}</em></div>
          </td>
   
          <td>
            <table class="for_print" >

              <tbody>
                <tr v-for="book in books[ddc]" :key="book.id">
                  <td><strong>{{book.call_number }}</strong></td>
                  <td class="book_title">{{book.title }}</td>
                  <td class="book_author_list">
                    {{book.author }}
                  </td>
                  <td class="book_author_list">
                    {{book.publisher }} 
                    <div v-show="book.artist"><br />Artist: {{ book.artist }}</div>
                  </td>
                  <td>
                    id: {{ book.id }} 
                    <span v-if="book.copies > 1"> | copies: {{ book.copies }}</span>
                  </td>
                  
                  <td>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>

</template>
<script>
import normalize from 'json-api-normalizer'
export default {
  name: 'PrintList',
  data () {
    return {
      done: false,
      fetching: true,
      deweys: {},
      updating: false,
      paginationMeta: null,
      totalPages: null,
      books: null,
      restrictSection: null,
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      }
    }
  },
  methods : {
    toggleStatus(bookId, status) {
      let url = '/v1/books/' + bookId
      this.axios.put(url, { status_id: status })
        .then((response) => {
          this.books.splice(this.books.findIndex(p => p.id === bookId), 1, response.data.data)
        })
    },
    cleanPartnership(bookIndex) {
      this.updating = true
      let url = '/v1/housekeeping/' + this.books[bookIndex].id + '/clean_commas'
      this.axios.get(url)
        .then((response) => {
          if (response.status === 200) {
            this.books.splice(bookIndex, 1, response.data.data)
          }
          this.updating = false
        })
    }
  },
  mounted () {
    this.axios.get("/v1/housekeeping/dewey_entries")
        .then((response) => {
          response.data.data.forEach((dewey) => {
            this.deweys[dewey.attributes.code] = dewey.attributes.name_with_ancestry
          })
        })

    this.axios.get('/v1/housekeeping/all_books')
      .then((response) => {
        this.books = response.data.books
        // this.dewey = response.data.classification.data
        this.fetching = false
      })   

      .then(() => {
        this.done = true
      })
  }
}
</script>
